#contact {
  background-color: rgb(54, 59, 61);
}

.contactTitle h3 {
  display: none;
  margin: 0 auto;
  letter-spacing: 4px;
}

.contactTitle i {
  padding-left: 1rem;
  transition: all 0.2s ease-in-out;
}

.contactTitle i:hover {
  transform: scale(1.2);
}

.asterisk {
  color: rgb(0, 201, 201);
}

.ui.input {
  width: 100%;
}

.InputColor,
.InputColor input {
  color: floralwhite !important;
  background-color: rgb(32, 35, 36) !important;
}

.InputColor:focus,
.InputColor input:focus {
  color: floralwhite !important;
  background-color: teal !important;
}

#contactMe p {
  color: rgb(175, 170, 162);
}

#contactMe p a {
  text-decoration: none;
  color: inherit;
}

@media only screen and (max-width: 767px) {
  .contactTitle h3 {
    display: block;
  }

  .contactTitle i {
    display: none !important;
  }
}
