#resume {
  background-color: rgb(54, 59, 61);
}

.division {
  padding-bottom: 2rem;
}

.division:last-child {
  padding-bottom: 0;
}

#resume p,
#resume strong {
  color: rgb(175, 170, 162);
}

#resume h4 span {
  border-bottom: 3px solid rgb(0, 250, 250);
  padding-bottom: 6px;
  letter-spacing: 2px;
}

#resume .Skill {
  transition: all 0.2s ease-in-out;
  text-align: center;
}

#resume .Skill:hover {
  transform: scale(1.35);
}

.BgWhite {
  background-color: white !important;
}

@media only screen and (max-width: 767px) {
  #resume h4 {
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
}
