#projects h2 {
  padding-bottom: 2rem;
  text-align: center;
}

.Project {
  text-align: center;
}

.Project > * {
  transform: translateY(50%);
  margin: 0.1rem;
  margin: 0 auto !important;
}

.Project .More {
  display: none !important;
}

@media only screen and (max-width: 991px) {
  .Project {
    transform: translateY(10%);
  }

  .Project > * {
    font-size: 2em;
    margin: 0.5rem auto !important;
  }
}

@media only screen and (max-width: 767px) {
  .Project {
    transform: translateY(0%);
  }

  .Project > * {
    font-size: 1.5rem;
  }

  .Project .Plus {
    display: none !important;
  }

  .Project .More {
    display: block !important;
    width: 15rem;
  }
}

@media only screen and (max-width: 375px) {
  .Project > * {
    font-size: 1rem;
    transform: translateY(10%);
  }
}
