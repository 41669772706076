header {
  position: relative;
  height: 100vh;
  min-height: 500px;
  width: 100%;
  background: #161415
    url("http://lffuent.web.app/images/jakob-korber-Xnz6Iq3BUbE-unsplash.jpg")
    no-repeat top center;
  background-size: cover;
  /* -webkit-background-size: cover !important; */
  text-align: center;
  overflow: hidden;
}

.Navbar div {
  display: flex;
  justify-content: center;
}

#menuTop {
  width: 100%;
  z-index: 100;
  font-size: 0.9em !important;
  letter-spacing: 3px;
  position: fixed;
  background: transparent;
}

.Sidebar {
  display: none;
  height: 100%;
  position: fixed;
  right: -100px;
  z-index: 150;
}

.sidebarOpen {
  animation: opened;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.sidebarButton {
  position: relative;
  margin-right: 20px;
}

#menuRight {
  position: relative !important;
  width: 100px;
  font-size: 0.8rem;
}

@keyframes opened {
  0% {
    right: -100px;
  }

  100% {
    right: 0;
  }
}

.banner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  width: 100%;
  text-align: center;
  font-size: inherit;
}

.banner-text h1 {
  width: 85%;
  font-size: 5.5em;
  margin: 0 auto;
  margin-bottom: 18px;
  text-shadow: 0 1px 3px rgb(0, 0, 0, 0.8);
}

.banner-text h3 {
  font-family: "Libre Baskerville", serif;
  font-weight: 300;
  font-size: 1.4em;
  color: rgb(216, 216, 216);
  margin: 0 auto;
  width: 60%;
  padding-bottom: 1em;
}

#arrow_down {
  position: absolute;
  bottom: 2rem;
  transform: translateX(-20px);
  transition: all 0.2s ease-in-out;
}

#arrow_down:hover {
  transform: scale(1.5) perspective(1px) translate(-12.5px);
}

#backgroundCredit {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;
  font-size: 0.75rem;
  color: rgb(177, 171, 161);
}

@media only screen and (max-width: 900px) {
  .banner {
    font-size: 0.9em;
  }

  .banner-text h1 {
    font-size: 5em;
  }
}

@media only screen and (max-width: 767px) {
  .banner {
    font-size: 0.8em;
  }

  .banner-text h1 {
    font-size: 4em;
  }

  .Navbar {
    display: none;
  }

  .Sidebar {
    display: inline-flex;
    align-items: baseline;
  }
}

@media only screen and (max-width: 480px) {
  .banner {
    font-size: 0.8em;
  }

  .banner-text h1 {
    font-size: 3em;
  }

  #backgroundCredit {
    padding: 0.6rem;
  }
}
