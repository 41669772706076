* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  color: #fff;
}

html,
body {
  scroll-behavior: smooth;
  font-size: 18px !important;
  font-family: "Open Sans", sans-serif;
}

body {
  line-height: 30px;
  background-color: rgb(32, 35, 36) !important;
}

.ui.placeholder.lighter,
.ui.placeholder.lighter .line,
.ui.placeholder.lighter .line:after,
.ui.placeholder.lighter > :before {
  background-color: rgb(54, 59, 61) !important;
}

.ui.placeholder.darker,
.ui.placeholder.darker .line,
.ui.placeholder.darker .line:after,
.ui.placeholder.darker > :before {
  background-color: rgb(32, 35, 36) !important;
}

.Row {
  width: 96%;
  max-width: 1020px;
  margin: 0 auto;
}

section {
  padding-top: 90px;
  padding-bottom: 60px;
}

.social {
  padding: 1em;
  font-size: 1.3em;
}

.social li {
  display: inline;
  padding: 0.5em;
}

.social li i:hover {
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 767px) {
  #arrow_up,
  #arrow_down {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  section {
    padding-top: 45px;
    padding-bottom: 30px;
  }
}

header {
  position: relative;
  height: 100vh;
  min-height: 500px;
  width: 100%;
  background: #161415
    url("http://lffuent.web.app/images/jakob-korber-Xnz6Iq3BUbE-unsplash.jpg")
    no-repeat top center;
  background-size: cover;
  /* -webkit-background-size: cover !important; */
  text-align: center;
  overflow: hidden;
}

.Navbar div {
  display: flex;
  justify-content: center;
}

#menuTop {
  width: 100%;
  z-index: 100;
  font-size: 0.9em !important;
  letter-spacing: 3px;
  position: fixed;
  background: transparent;
}

.Sidebar {
  display: none;
  height: 100%;
  position: fixed;
  right: -100px;
  z-index: 150;
}

.sidebarOpen {
  -webkit-animation: opened;
          animation: opened;
  -webkit-animation-duration: 0.25s;
          animation-duration: 0.25s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.sidebarButton {
  position: relative;
  margin-right: 20px;
}

#menuRight {
  position: relative !important;
  width: 100px;
  font-size: 0.8rem;
}

@-webkit-keyframes opened {
  0% {
    right: -100px;
  }

  100% {
    right: 0;
  }
}

@keyframes opened {
  0% {
    right: -100px;
  }

  100% {
    right: 0;
  }
}

.banner {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin: 0 auto;
  width: 100%;
  text-align: center;
  font-size: inherit;
}

.banner-text h1 {
  width: 85%;
  font-size: 5.5em;
  margin: 0 auto;
  margin-bottom: 18px;
  text-shadow: 0 1px 3px rgb(0, 0, 0, 0.8);
}

.banner-text h3 {
  font-family: "Libre Baskerville", serif;
  font-weight: 300;
  font-size: 1.4em;
  color: rgb(216, 216, 216);
  margin: 0 auto;
  width: 60%;
  padding-bottom: 1em;
}

#arrow_down {
  position: absolute;
  bottom: 2rem;
  -webkit-transform: translateX(-20px);
          transform: translateX(-20px);
  transition: all 0.2s ease-in-out;
}

#arrow_down:hover {
  -webkit-transform: scale(1.5) perspective(1px) translate(-12.5px);
          transform: scale(1.5) perspective(1px) translate(-12.5px);
}

#backgroundCredit {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;
  font-size: 0.75rem;
  color: rgb(177, 171, 161);
}

@media only screen and (max-width: 900px) {
  .banner {
    font-size: 0.9em;
  }

  .banner-text h1 {
    font-size: 5em;
  }
}

@media only screen and (max-width: 767px) {
  .banner {
    font-size: 0.8em;
  }

  .banner-text h1 {
    font-size: 4em;
  }

  .Navbar {
    display: none;
  }

  .Sidebar {
    display: inline-flex;
    align-items: baseline;
  }
}

@media only screen and (max-width: 480px) {
  .banner {
    font-size: 0.8em;
  }

  .banner-text h1 {
    font-size: 3em;
  }

  #backgroundCredit {
    padding: 0.6rem;
  }
}

#about p {
  color: rgb(175, 170, 162);
  font-size: 1em;
}

#about a {
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: inherit;
}

#about a:hover {
  -webkit-transform: scale(1.1) !important;
          transform: scale(1.1) !important;
}

#resume {
  background-color: rgb(54, 59, 61);
}

.division {
  padding-bottom: 2rem;
}

.division:last-child {
  padding-bottom: 0;
}

#resume p,
#resume strong {
  color: rgb(175, 170, 162);
}

#resume h4 span {
  border-bottom: 3px solid rgb(0, 250, 250);
  padding-bottom: 6px;
  letter-spacing: 2px;
}

#resume .Skill {
  transition: all 0.2s ease-in-out;
  text-align: center;
}

#resume .Skill:hover {
  -webkit-transform: scale(1.35);
          transform: scale(1.35);
}

.BgWhite {
  background-color: white !important;
}

@media only screen and (max-width: 767px) {
  #resume h4 {
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
}

#projects h2 {
  padding-bottom: 2rem;
  text-align: center;
}

.Project {
  text-align: center;
}

.Project > * {
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  margin: 0.1rem;
  margin: 0 auto !important;
}

.Project .More {
  display: none !important;
}

@media only screen and (max-width: 991px) {
  .Project {
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
  }

  .Project > * {
    font-size: 2em;
    margin: 0.5rem auto !important;
  }
}

@media only screen and (max-width: 767px) {
  .Project {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }

  .Project > * {
    font-size: 1.5rem;
  }

  .Project .Plus {
    display: none !important;
  }

  .Project .More {
    display: block !important;
    width: 15rem;
  }
}

@media only screen and (max-width: 375px) {
  .Project > * {
    font-size: 1rem;
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
  }
}

#contact {
  background-color: rgb(54, 59, 61);
}

.contactTitle h3 {
  display: none;
  margin: 0 auto;
  letter-spacing: 4px;
}

.contactTitle i {
  padding-left: 1rem;
  transition: all 0.2s ease-in-out;
}

.contactTitle i:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.asterisk {
  color: rgb(0, 201, 201);
}

.ui.input {
  width: 100%;
}

.InputColor,
.InputColor input {
  color: floralwhite !important;
  background-color: rgb(32, 35, 36) !important;
}

.InputColor:focus,
.InputColor input:focus {
  color: floralwhite !important;
  background-color: teal !important;
}

#contactMe p {
  color: rgb(175, 170, 162);
}

#contactMe p a {
  text-decoration: none;
  color: inherit;
}

@media only screen and (max-width: 767px) {
  .contactTitle h3 {
    display: block;
  }

  .contactTitle i {
    display: none !important;
  }
}

#footer {
  padding-top: 60px !important;
}

.social {
  text-align: center;
  position: relative;
}

#arrow_up {
  text-align: center;
}

#arrow_up i.icon {
  position: absolute;
  -webkit-transform: translate(-20px, -75px);
          transform: translate(-20px, -75px);
  transition: all 0.2s ease-in-out;
}

#arrow_up i.icon:hover {
  -webkit-transform: scale(1.5) perspective(1px) translate(-13px, -50px);
          transform: scale(1.5) perspective(1px) translate(-13px, -50px);
}

#credits {
  text-align: center;
  font-size: 0.9rem;
  margin: 0 auto;
}

#credits p {
  color: rgb(175, 170, 162);
}

.copyright li {
  display: inline;
  color: rgb(175, 170, 162);
}

.copyright li::before {
  content: "\2022";
  padding-left: 10px;
}

.copyright li:first-child::before {
  display: none;
}

@media only screen and (max-width: 1024px) {
  #credits {
    width: 95%;
  }
}

@media only screen and (max-width: 480px) {
  #footer {
    padding-top: 10px !important;
  }
}


