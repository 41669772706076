* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  color: #fff;
}

html,
body {
  scroll-behavior: smooth;
  font-size: 18px !important;
  font-family: "Open Sans", sans-serif;
}

body {
  line-height: 30px;
  background-color: rgb(32, 35, 36) !important;
}

.ui.placeholder.lighter,
.ui.placeholder.lighter .line,
.ui.placeholder.lighter .line:after,
.ui.placeholder.lighter > :before {
  background-color: rgb(54, 59, 61) !important;
}

.ui.placeholder.darker,
.ui.placeholder.darker .line,
.ui.placeholder.darker .line:after,
.ui.placeholder.darker > :before {
  background-color: rgb(32, 35, 36) !important;
}

.Row {
  width: 96%;
  max-width: 1020px;
  margin: 0 auto;
}

section {
  padding-top: 90px;
  padding-bottom: 60px;
}

.social {
  padding: 1em;
  font-size: 1.3em;
}

.social li {
  display: inline;
  padding: 0.5em;
}

.social li i:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.2);
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 767px) {
  #arrow_up,
  #arrow_down {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  section {
    padding-top: 45px;
    padding-bottom: 30px;
  }
}
