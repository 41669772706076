#about p {
  color: rgb(175, 170, 162);
  font-size: 1em;
}

#about a {
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: inherit;
}

#about a:hover {
  transform: scale(1.1) !important;
}
